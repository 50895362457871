import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SimpleSubPageHero from "../components/simpleSubPageHero"

const PrivacyPolicy = () => {
  const heroData = {
    bannerText: "Twoje dane",
    header: "Polityka Prywatności",
    description:
      "Informacje dotyczące administracji, przechowywania i zarządzania Twoimi danymi pozyskanymi z tej strony internetowej.",
  }

  const privacyPolicyData = [
    {
      name: "Administrator danych osobowych i postanowienia ogólne",
      paragraphs: [
        "Niniejsza Polityka Prywatności i Polityka Cookies określa zasady przetwarzania i ochrony danych osobowych przekazanych przez Użytkowników oraz plików Cookies, a także innych technologii pojawiających się na stronie internetowej zjednoczeniponadpodzialami.pl. Zawiera również politykę i zasady korzystania z formularzy na stronie.",
        "Administratorem strony i danych osobowych jest jednostka organizacyjna niemająca osobowości prawnej o nazwie Zjednoczeni Ponad Podziałami, NIP 1231422380, REGON 382346751, zwana dalej Administratorem.",
        "W przypadku pojawienia się jakichkolwiek wątpliwości w zakresie postanowień niniejszej Polityki Prywatności i Polityki Cookies proszę kontaktować się z Administratorem poprzez adres e-mail: zjednoczeniponadpodzialami@gmail.com",
        "Wszelkie dane osobowe i adresowe podane przez Użytkownika na stronie zjednoczeniponadpodzialami.pl nie będą w jakikolwiek sposób odsprzedawane osobom czy podmiotom trzecim.",
        "Administrator zastrzega sobie prawo do wprowadzania zmian w Polityce prywatności i polityce cookies, a każdego Użytkownika strony obowiązuje znajomość aktualnej polityki prywatności. Przyczyną zmian mogą być rozwój technologii internetowej, zmiany w powszechnie obowiązującym prawie czy też rozwój Strony. Na dole strony znajduje się data publikacji aktualnej Polityki prywatności.",
      ],
    },
    {
      name: "Informacje dot. danych osobowych",
      paragraphs: [
        "1. Administratorem danych osobowych Użytkownika jest Administrator.",
        "2. Użytkownik dobrowolnie podaje dane osobowe za pomocą formularzy znajdujących się na Stronie, o których mowa poniżej, w celach na jakie wskazują dane formularze.",
        "3. Zbierane i przetwarzane są tylko te dane, które użytkownik sam poda (z wyjątkiem – w pewnych sytuacjach – danych zbieranych automatycznie za pomocą plików cookies oraz danych logowania, o czym mowa poniżej). Podczas wizyty na stronie, automatycznie zbierane są dane dotyczące samej wizyty, np. adres IP użytkownika, nazwa domeny, typ przeglądarki, typ systemu operacyjnego itp. (dane logowania). Dane zbierane automatycznie mogą być użyte do analizy zachowań użytkowników na stronie internetowej, zbierania danych demograficznych o użytkownikach, lub do personalizacji zawartości strony w celu jej ulepszenia. Dane te przetwarzane są jednak wyłącznie w celach administrowania stroną i zapewnienia sprawnej obsługi hostingowej i nie są kojarzone z danymi poszczególnych Użytkowników.",
        "4. Użytkownik wyraża zgodę na przetwarzanie podanych danych osobowych oraz na otrzymywanie informacji marketingowych środkami komunikacji elektronicznej od Administratora w celu wysyłania newslettera, kontaktu z Użytkownikiem lub wysyłania innych informacji marketingowych, np. o wydarzeniach lub produktach online oferowanych przez Administratora, jeżeli wyraził w tych celach odpowiednią zgodę i/lub jeżeli mieści się to w granicach prawnie uzasadnionego interesu Administratora.",
        "5. Dane osobowe przekazywane przez Użytkownika w ramach funkcjonowania Strony internetowej zjednoczeniponadpodzialami.pl mogą być przetwarzane w następujących celach i na następujących podstawach prawnych:",
        " - wystawienia faktury i spełnienia innych obowiązków wynikających z przepisów prawa podatkowego — na podstawie art. 6 ust. 1 lit. c RODO (obowiązek wynikający z przepisów prawa);",
        " - rozpatrzenia reklamacji czy roszczeń — na podstawie art. 6 ust. 1 lit. b RODO (niezbędność do zawarcia i/lub wykonania umowy);",
        " - ustalenia, dochodzenia lub obrony przed roszczeniami — na podstawie art. 6 ust. 1 lit. f) RODO (prawnie uzasadniony interes administratora);",
        " - kontaktu telefonicznego w sprawach związanych z realizacją usługi — na podstawie art. 6 ust. 1 lit. a RODO (zgoda);",
        " - archiwalnym i dowodowym, na potrzeby zabezpieczenia informacji, które mogą służyć wykazywaniu faktów — na podstawie art. 6 ust. 1 lit. f) RODO (prawnie uzasadniony interes administratora);",
        " - analitycznym, polegającym między innymi na analizie danych zbieranych automatycznie przy korzystaniu ze strony internetowej, w tym cookies Google Analytics — na podstawie art. 6 ust. 1 lit. f) RODO (prawnie uzasadniony interes administratora);",
        "6. Podanie danych jest dobrowolne, jednakże nie podanie pewnych informacji, co do zasady zaznaczonych na stronach Administratora jako obowiązkowe, wiązać się będzie z brakiem możliwości wykonania danej usługi i osiągnięcia określonego celu.",
        "7. Podanie przez Użytkownika danych, które nie są obowiązkowe lub nadmiaru danych, których Administrator nie potrzebuje przetwarzać następuje na podstawie decyzji samego Użytkownika i wówczas przetwarzanie odbywa się na podstawie przesłanki zawartej w art. 6 ust. 1 lit. a) RODO (zgoda). Użytkownik udziela zgody na przetwarzanie tych danych oraz na zanonimizowanie danych, których Administrator nie wymaga i nie chce przetwarzać, a mimo to Użytkownik przekazał je Administratorowi;",
        "8. Użytkownikowi przysługuje w każdej chwili prawo dostępu do treści jego danych, ich poprawiania, ich sprostowania, usunięcia lub ograniczenia przetwarzania, prawo do wniesienia sprzeciwu wobec przetwarzania, prawo do przenoszenia danych, prawo żądania dostępu do danych, a także prawo wniesienia skargi do organu nadzorczego – Prezesa Urzędu Ochrony Danych Osobowych (na zasadach określonych w ustawie o ochronie danych osobowych), jeśli uzna, że przetwarzanie jego danych jest niezgodne z aktualnie obowiązującymi przepisami prawa w zakresie ochrony danych. Przysługuje mu też prawo do bycia zapomnianym, jeśli dalsze przetwarzanie nie będzie przewidziane przez aktualnie obowiązujące przepisy prawa.",
        "9. Dane Użytkownika nie będą przekazywane poza obszar Unii Europejskiej, do państw trzecich, a jeśli dojdzie do takiej sytuacji to będą one przekazywanie wyłącznie odbiorcom, którzy przystąpili do porozumienia „Tarcza prywatności – Privacy Shield”. Z uwagi na korzystanie z usług Google dane Użytkownika mogą być przekazywane do Stanów Zjednoczonych Ameryki (USA) w związku z ich przechowywaniem na amerykańskich serwerach. Google przystąpił do programu Privacy Shield i w ten sposób gwarantuje odpowiedni poziom ochrony danych osobowych wymagany przez przepisy prawa Unii Europejskiej.",
        "10. Dane Użytkownika będą przechowywane przez Administratora przez czas realizacji poszczególnych usług/celów oraz:",
        " - przez okres przedawnienia roszczeń zgodnie z przepisami Kodeksu postępowania cywilnego;",
        " - przez okres wymagany przepisami prawa podatkowego — w odniesieniu do danych osobowych wiążących się ze spełnieniem obowiązków z prawa podatkowego;",
        " - do czasu skutecznego wniesienia sprzeciwu — w odniesieniu do danych osobowych przetwarzanych na podstawie prawnie uzasadnionego interesu administratora lub do celów marketingowych;",
        " - do czasu cofnięcia zgody lub osiągnięcia celu przetwarzania — w odniesieniu do danych osobowych przetwarzanych na podstawie zgody;",
        " - do czasu zdezaktualizowania się lub utraty przydatności — w odniesieniu do danych osobowych przetwarzanych głównie do celów analitycznych, statystycznych, wykorzystania cookies i administrowania Stronami Administratora.",
        "11. Jeśli Użytkownik nie poda swoich danych osobowych, które są wymagane, to nie będzie mógł skorzystać z materiałów lub usług oferowanych przez Administratora w ramach formularzy znajdujących się na Stronie.",
        "12. Na Stronie mogą pojawiać się linki odsyłające do innych stron internetowych. Będą one otwierać się w nowym oknie przeglądarki lub w tym samym oknie. Administrator nie odpowiada za treści przekazywane przez te strony. Użytkownik zobowiązany jest do zapoznania się z polityką prywatności lub regulaminem tych stron.",
        "13. Użytkownik podaje dane dobrowolnie jednakże jest to konieczne do osiągnięcia celu lub podjęcia działań związanych z ich podaniem.",
        "14. Dane osobowe Użytkownika są przechowywane i chronione z należytą starannością, zgodnie z wdrożonymi procedurami wewnętrznymi Administratora. Administrator przetwarza informacje o Użytkowniku przy użyciu odpowiednich środków technicznych i organizacyjnych spełniających wymagania przepisów prawa powszechnie obowiązującego. Środki te mają na celu przede wszystkim zabezpieczenie danych osobowych Użytkowników przed dostępem osób nieupoważnionych. W szczególności, dostęp do danych osobowych Użytkowników mają jedynie osoby upoważnione, które są zobowiązane do zachowania tych danych w tajemnicy.",
        "15. Administrator niniejszym informuje Użytkownika, że powierza przetwarzanie danych osobowych m.in. następującym podmiotom:",
        " - Google LLC – w celu korzystania z usług analitycznych Google (przekazywane dane: długość wizyty, przybliżona do najbliższego dużego miasta lokalizacja, nawigacja po stronie oraz inne metadane związane z funkcjonowaniem strony internetowej)",
        "16. Podmioty, którym Administrator powierza przetwarzanie danych osobowych gwarantują wypełnianie obowiązków nałożonych przez RODO i stosowanie odpowiednich środków ochrony i bezpieczeństwa danych osobowych.",
        "17. Administrator Danych Osobowych niniejszym informuje, że nie powołał Inspektora Ochrony Danych Osobowych (IODO) i wykonuje samodzielnie obowiązki związane z przetwarzaniem danych osobowych.",
        "18. Użytkownik przyjmuje do wiadomości, iż jego dane osobowe mogą być przekazywane uprawnionym organom państwowym w związku z prowadzonymi przez nie postępowaniami, na ich żądanie i po spełnieniu przesłanek potwierdzających niezbędność pozyskania od nas tych danych.",
        "19. Dane osobowe Użytkownika nie będą wykorzystywane do zautomatyzowanego podejmowania decyzji, w tym profilowania.",
      ],
    },
    {
      name: "Formularze",
      paragraphs: [
        "Administrator stosuje dwa rodzaje formularzy w ramach Strony:",
        "1. Formularz kontaktowy w stopce – wymaga podania w odpowiednim formularzu swojego imienia, adresu e-mail, wiadomości do Administratora oraz opcjonalnie numeru telefonu.",
        "2. Formularz służący do przesyłania wypełnionych kart referendum - wymagane od użytkownika dane: imię, nazwisko, adres e-mail, numer telefonu oraz pliki zawierające wypełnione karty referendum. Wszystkie dane użytkownika są szyfrowane przed umieszczeniem ich na prywatnym serwerze - w przypadku ataku hakerskiego dane te będą bezpieczne.",
      ],
    },
    {
      name: "Cookies",
      paragraphs: [
        "1. Strona nie zbiera w sposób automatyczny żadnych informacji, z wyjątkiem informacji zawartych w plikach cookies.",
        "2. Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Strony i przeznaczone są do korzystania ze Strony. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.",
        "3. Pliki cookies wykorzystywane są w celu:",
        " - dostosowania zawartości stron internetowych Strony do preferencji Użytkownika oraz optymalizacji korzystania ze stron internetowych; w szczególności pliki te pozwalają rozpoznać urządzenie Użytkownika Strony i odpowiednio wyświetlić stronę internetową, dostosowaną do jego indywidualnych potrzeb;",
        " - tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy korzystają ze Strony, co umożliwia ulepszanie jej struktury i zawartości;",
        "4. W ramach Strony stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne”  (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.",
        "5. W ramach Serwisu stosowane są następujące rodzaje plików cookies:",
        " - „niezbędne” pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach Strony, np. uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania w ramach Strony;",
        " - pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w ramach Strony;",
        " - „wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania ze Strony;",
        " - „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez Użytkownika ustawień i personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.;",
        "6. W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych (przeglądarka internetowa) domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać w każdym czasie zmiany ustawień dotyczących plików cookies. Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika Strony. Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).",
        "7. Administrator informuje, że ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Strony.",
      ],
    },
  ]

  return (
    <>
      <Layout>
        <Seo title="Polityka Prywatności" />
        <SimpleSubPageHero
          bannerText={heroData.bannerText}
          header={heroData.header}
          description={heroData.description}
        />
        <div className="max-w-3xl mx-auto">
          <p className="mb-4">
            Przebywanie i korzystanie ze strony zjednoczeniponadpodzialami.pl
            jest równoznaczne ze zrozumieniem i akceptacją poniższej polityki
            prywatności.
          </p>
          <div>
            {privacyPolicyData.map(e => {
              return (
                <div>
                  <span className="block font-semibold uppercase mb-2 underline underline-offset-2">
                    {e.name}
                  </span>
                  <div>
                    {e.paragraphs.map(e => {
                      return <p className="block my-2 text-justify">{e}</p>
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PrivacyPolicy
